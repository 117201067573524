.titulo {
  margin-top: 70px;
}
.btn {
  margin-right: 10px;
  margin-bottom: 10px;
}
.btn-action {
  margin: 10px;
  min-width: 80px;
}
.icon-action {
  margin-left: 5px;
  margin-right: 5px;
}
.red {
  color: rgb(194, 74, 74);
}
.green {
  color: rgb(55, 124, 55);
}
.ref {
  margin-top: auto;
}
.modal-footer {
  justify-content: flex-start;
  align-items: center;
}
textarea.form-control {
  min-height: calc(1.5em + .75rem + calc(var(--bs-border-width) * 2));
}