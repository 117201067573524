/**
 * src/components/menu/index.css
 */

#menu {
  background-color: #000;
  color: white;
  padding: 5px;
}


.sidebar {
  width: 200px;
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: none; /* Oculta por padrão */
  flex-direction: row;
  justify-content: space-around;
  /* padding: 10px 0; */
  background-color: #000;
  z-index: 9999;
}

.tabbar .nav-justified {
  display: flex;
  width: 100%;
}

.tabbar .nav-item {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabbar .nav-link {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tabbar .icon {
  margin-bottom: 4px;
}

/* Responsividade */

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .tabbar {
    display: flex;
  }
}

@media (min-width: 769px) {
  .tabbar {
    display: none;
  }
}

@media (min-width: 576px) {
  .px-sm-2 {
      padding-right: 0 !important;
      padding-left: 0 !important;
  }
}