/* src/app/pedidos/pedido.css */

.pedido {
  background-color: #FFF;
  min-height: 100px;
  border-radius: 10px;
  margin-bottom: 20px;
  border: 1px solid rgb(90, 90, 90);
  padding: 15px;
}
.pedido text-black {
  color: #000;
  background-color: #FFF;
}
.pedido text-orange {
  color: orangered;
  background-color: #FFF;
}
.pedido text-white {
  color: #FFF;
  background-color: #FFF;
}
.pedido a {
  cursor: pointer;
}
.foto-item {
  max-width: 100px;
}
.card-pedido {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 200px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px;
  border-radius: .25rem;
  padding: 15px;
}
.acrescimos ul {
  min-height: 100px;
}
.obs {
  min-height: 50px; /* Defina a altura mínima desejada */
}
.bg-warning {
  color: #000;
}
