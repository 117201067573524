/* banner.css */

#banner .container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh; 
}

.fade-in {
  animation: fadeIn 1500ms ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 1500ms ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.bloco {
  display: flex;
  flex-direction: column;
  justify-content: left;
  height: 100%;
}

.img-fluid {
  max-width: 100%;
  max-height: 80vh; /* Limite a altura máxima da imagem para evitar corte */
  height: auto;
  object-fit: contain; /* Garante que a imagem seja ajustada dentro do contêiner */
}
