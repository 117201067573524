/**
 * src/app/login/login.css
 */

.form-container {
  height: 100vh;
}
.form-login {
  width: 330px;
  padding: 15px;
  margin: auto;
}
.form-login img {
  width: 50%;
  margin: 20px;
}
.form-login input[type="password"] {
  margin-top: 10px;
  margin-bottom: 10px;
}
.form-links a {
  text-decoration: none;
  font-size: 10pt;
}

